import React, { FC } from "react"
import { PageContext } from "gatsby/internal"

const Layout: FC<{ path: string; pageContext: PageContext }> = ({
  children,
}) => {
  return <div>{children}</div>
}

export default Layout
