import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client"
import { useAuthToken } from "./authToken"
import fetch from "isomorphic-fetch"
import config from "../config/config"
import { createUploadLink } from "apollo-upload-client"

// const httpLink = createHttpLink({
//   uri: config.graphqlApiUrl,
//   fetch: fetch,
// })

const httpLink = createUploadLink({
  uri: config.graphqlApiUrl,
  fetch: fetch,
})

const authMiddleware = (authToken: string) =>
  new ApolloLink((operation, forward) => {
    if (authToken) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${authToken}`,
        },
      }))
    }

    return forward(operation)
  })

const cache = new InMemoryCache({})

export const useAppApolloClient = () => {
  const [authToken] = useAuthToken()
  return new ApolloClient({
    link: authMiddleware(authToken).concat(httpLink),
    cache,
  })
}

export const useGuestApolloClient = () => {
  return new ApolloClient({
    link: httpLink,
    cache,
  })
}
