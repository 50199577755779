import { ApolloProvider } from "react-apollo"
import React, { FC } from "react"
import { useAppApolloClient } from "services/client"

const AuthWrapper: FC = ({ children }) => {
  const client = useAppApolloClient()
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default AuthWrapper
